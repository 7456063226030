import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import { store } from "./vuex/store";

import Axios from "axios";

require("bootstrap");
window.$ = window.jQuery = require("jquery");

Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;

new Vue({
  created() {
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch("userRequest");
    }
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
