import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../vuex/store";

import NotFoundComponent from "../views/Errors/404";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next({ name: "home" });
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  localStorage.setItem("intended", to.path);
  next({ name: "login" });
};

const routes = [
  {
    path: "/",
    component: () => import("../views/Layout/App.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/Index.vue")
      },
      {
        path: "/login",
        name: "login",
        component: () => import("../views/Auth/Login.vue"),
        beforeEnter: ifNotAuthenticated
      },
      {
        path: "/register",
        name: "register",
        component: () => import("../views/Auth/Register.vue")
        // beforeEnter: ifNotAuthenticated
      },
      {
        path: "/shop",
        redirect: {
          name: "shop-list"
        }
      },
      {
        path: "/shop/:id",
        name: "shop",
        component: () => import("../views/Pages/Shop.vue")
        // beforeEnter: ifNotAuthenticated
      },
      {
        path: "/shop-list",
        name: "shop-list",
        component: () => import("../views/Pages/ShopList.vue")
        // beforeEnter: ifNotAuthenticated
      },
      {
        path: "/earnings",
        name: "earnings",
        component: () => import("../views/Pages/Earnings.vue")
        // beforeEnter: ifNotAuthenticated
      },
      {
        path: "/games",
        name: "games",
        component: () => import("../views/Pages/Games.vue")
        // beforeEnter: ifNotAuthenticated
      },
      {
        path: "/forgot",
        name: "forgot",
        component: () => import("../views/Auth/ForgetPassword.vue"),
        beforeEnter: ifNotAuthenticated
      },
      {
        path: "/team",
        component: () => import("../views/Pages/TeamBinary/TeamBinary.vue"),
        beforeEnter: ifAuthenticated,
        children: [
          {
            path: "/",
            redirect: "team-structure"
          },
          {
            path: "structure",
            name: "team-structure",
            component: () =>
              import("../views/Pages/TeamBinary/TeamStructure.vue")
          },
          {
            path: "commission",
            name: "team-commission",
            component: () => import("../views/Pages/TeamBinary/Commission.vue")
          },
          {
            path: "matching-bonus-leaders",
            name: "team-matching-bonus-leaders",
            component: () =>
              import("../views/Pages/TeamBinary/MatchingBonusLeaders.vue")
          }
        ]
      },
      {
        path: "/social-circle",
        component: () => import("../views/Pages/SocialCircle/SocialCircle.vue"),
        children: [
          {
            path: "/",
            redirect: "overview"
          },
          {
            name: "social-circle-overview",
            path: "overview",
            component: () => import("../views/Pages/SocialCircle/Overview.vue")
          },

          {
            path: "commission",
            name: "social-circle-commission",
            component: () =>
              import("../views/Pages/SocialCircle/Commission.vue")
          }
        ]
      },
      {
        path: "/account",
        component: () => import("../views/Account/Account.vue"),
        children: [
          {
            path: "/",
            redirect: "details"
          },
          {
            name: "account-details",
            path: "details",
            component: () => import("../views/Account/Details/Details.vue")
          },
          {
            name: "account-security",
            path: "security",
            component: () => import("../views/Account/Security.vue")
          },
          {
            name: "account-kyc",
            path: "kyc",
            component: () => import("../views/Account/KYC.vue")
          }
        ]
      },
      {
        path: "/finances",
        component: () => import("../views/Pages/Finance/Finances.vue"),
        beforeEnter: ifAuthenticated,
        children: [
          {
            path: "/",
            redirect: "balance"
          },
          {
            name: "finance-balance",
            path: "balance",
            component: () => import("../views/Pages/Finance/Balance.vue")
          },
          {
            name: "finances-transaction",
            path: "transaction",
            component: () => import("../views/Pages/Finance/Transaction.vue")
          },
          {
            name: "finances-wallets",
            path: "wallets",
            component: () => import("../views/Pages/Finance/Wallets.vue")
          }
        ]
      },
      {
        name: "support",
        path: "support",
        component: () => import("../views/Pages/Support.vue")
      }
    ]
  },
  {
    path: "*",
    name: "404",
    component: NotFoundComponent
  }
];

const router = new VueRouter({
  routes
  // mode: "history"
});

export default router;
