<template>
  <main>
    <div
      class="small-centered-container"
      style="position:absolute;top: 50%;left: 50%;transform: translateX(-50%);"
    >
      <h1 style="font-family:sans;">404 | Not found</h1>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
